import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from '../breadcrumb.service';
import jwt_decode from "jwt-decode";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  providers: [ConfirmationService],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  sub: Subscription;

  items: MenuItem[];

  menuItems: MenuItem[] | undefined;

  visible: boolean = false;
  curPwd = '';
  newPwd = '';
  newPwd2 = '';
  error = '';

  showDialog() {
    this.curPwd = '';
    this.newPwd = '';
    this.newPwd2 = '';
    this.error = '';
    this.visible = true;
  }

  user;

  constructor(
    private router: Router,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    public breadcrumbService: BreadcrumbService) {

    const jwt = localStorage.getItem('jwt');
    console.log('jwt', jwt);
    console.log('decoded', jwt_decode(jwt));
    console.log('env.user', environment.user);
    this.user = environment.user;

    this.sub = this.breadcrumbService.itemsHandler.subscribe(
      (response) => {
        setTimeout(() => {
          this.items = [...response];
        });
      }
    );
  }

  ngOnInit() {
    this.menuItems = [
      {
        label: this.user?.email,
        items: [
          {
            label: 'Change password',
            command: () => this.showDialog(),
            icon: 'fas fa-key'
          },
          {
            label: 'Logout',
            command: () => this.onLogout(),
            icon: 'fas fa-sign-out-alt'
          }
        ]
      }
    ];
  }
  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onLogout() {
    this.confirmationService.confirm({
      message:
        'Are you sure you want to log out?',
      accept: () => {
        localStorage.removeItem('jwt');
        environment.cmsAccessToken = null;
        this.router.navigate(['welcome']);
        return false;
      },
      reject: () => {
        return false;
      }
    });

  }

  onPwdSubmit() {

    const body = {
      "currentPassword": this.curPwd,
      "password": this.newPwd,
      "passwordConfirmation": this.newPwd2
    }

    // console.log('body', body);

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    // console.log('token', environment.cmsAccessToken);
    this.http.post('/cms-server/auth/change-password', body, { headers: headers }).toPromise().then((data: any) => {
      // this.http.post('/cms-server/auth/change-password', { headers: headers }).toPromise().then((data: any) => {
      // console.log('data', data);
      environment.cmsAccessToken = data.jwt;
      this.visible = false;
    }, (err => {
      console.log('err', err);
      this.error = 'Could not change password. Check that current password is correct.'
    }))



  }
}
