export const environment = {
  production: true,
  serviceWorkers: true,
  hmr: false,

  cmsAccessToken: null,
  user: null,

  gaCookie : true
};
