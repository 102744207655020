<!-- <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb> -->
<!-- ITEMS:{{ items?.length }} - {{ items  | json }} END {{ home | json}} -->

<p-confirmDialog header="Confirmation"></p-confirmDialog>

<div class="layout-breadcrumb">
  <ul>
    <li>
      <a routerLink="/">
        <i class="fas fa-home"></i>
      </a>
    </li>
    <li>/</li>
    <ng-template ngFor let-item let-last="last" [ngForOf]="items">
      <li>
        <a [routerLink]="item.routerLink" [queryParams]="item?.queryParams" *ngIf="item.routerLink">{{ item.label }}</a>
        <ng-container *ngIf="!item.routerLink">{{ item.label }}</ng-container>
      </li>
      <li *ngIf="!last">/</li>
    </ng-template>
  </ul>
  <div class="layout-breadcrumb-options">
    <!-- <a routerLink="/" title="Backup">
      <i class="fa fa-cloud-upload"></i>
    </a>
    <a routerLink="/" title="Bookmark">
      <i class="fa fa-bookmark"></i>
    </a> -->
    <!-- <a routerLink="/feeds" title="Feeds">
      <i class="fas fa-rss"></i>
    </a> -->

    <!-- {{user.role.type}} -->
    <ng-container *ngIf="user.role.type ==='cms_admin'">
      <a routerLink="/users" title="Users">
        <i class="fas fa-users"></i>
      </a>
    </ng-container>

    <a routerLink="/content-library" title="Content Library">
      <i class="fas fa-photo-video"></i>
    </a>

    <span class="card flex justify-center">
      <p-menu #menu [model]="menuItems" [popup]="true" />
      <a href="javascript:void(0);" (click)="menu.toggle($event)">
        <i class="fas fa-bars"></i>
      </a>
    </span>

  </div>
</div>

<div class="card flex justify-center">
  <p-dialog header="Change Password" [(visible)]="visible" [style]="{ width: '45rem' }">
    <form #form="ngForm">
      <div class="p-grid">
      <!-- <span class="p-text-secondary block mb-8">Update your information.</span> -->
      <div class=" p-col-fixed form-input-label">
        <label for="existingpwd" class="font-semibold w-24">Current password</label>
      </div>
      <div class="p-col form-input-field">
        <input pInputText id="existingpwd" #curPassword="ngModel" [(ngModel)]="curPwd" name="curPassword" class="flex-auto" autocomplete="off" required minlength="6" maxlength="40"/>
        <span [hidden]="curPassword.valid || curPassword.pristine" class="alert alert-danger">
          Required. Min 6 characters.
        </span>
      </div>
    </div>

    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="newpwd" class="font-semibold w-24">New password</label>
      </div>
      <div class="p-col form-input-field">
        <input pInputText id="newpwd" #newPassword="ngModel" [(ngModel)]="newPwd" name="newPassword" class="flex-auto" autocomplete="off" required minlength="6" maxlength="40"/>
        <span [hidden]="newPassword.valid || newPassword.pristine" class="alert alert-danger">
          Required. Min 6 characters.
        </span>
      </div>
    </div>

    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="newpwd2" class="font-semibold w-24">Confirm password</label>
      </div>
      <div class="p-col form-input-field">
        <input pInputText id="newpwd2" #newPassword2="ngModel" #newpass2="ngModel" name="newpass2" [(ngModel)]="newPwd2" [ngModelOptions]="{standalone: true}" class="flex-auto" autocomplete="off" required minlength="6" maxlength="40"/>
        <span [hidden]="newPassword2.valid || newPassword2.pristine" class="alert alert-danger">
          Required. Min 6 characters.
        </span>
      </div>
    </div>

    <div *ngIf="error"><p-message  severity="error" [text]="error"></p-message><br/><br/></div>

    <div class="right grouped-buttons">
      <button pButton type=" cancel" label="Cancel" (click)="visible = false" class="ui-button-secondar ui-button"></button>
      <button pButton type="submit" label="Save" (click)="onPwdSubmit()" class="ui-button" [disabled]="!form.valid || (newPwd !== newPwd2)" ></button>
    </div>


    </form>
  </p-dialog>
</div>